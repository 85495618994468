/* globals document, localStorage, location */
/**
 * Webflow: Interactions 2
 */
var Webflow = require('./webflow-lib');

Webflow.define(
  'analytics',
  (module.exports = function ($) {
    var $html = $(document.documentElement);
    return {
      ready: () => {
        Webflow.analytics = {
          optOut: function (opts) {
            const {reload = true} = opts || {};
            const intellimizeCustomerId = $html.attr(
              'data-wf-intellimize-customer-id'
            );
            if (intellimizeCustomerId) {
              const optOutKey = `intellimize_opt_out_${intellimizeCustomerId}`;
              const intellimizeOptOut = localStorage.getItem(optOutKey);
              if (intellimizeOptOut) {
                return;
              }
              localStorage.setItem(
                `intellimize_opt_out_${intellimizeCustomerId}`,
                true
              );
              if (reload) {
                location.reload();
              }
            }
          },
          optIn: function (opts) {
            const {reload = true} = opts || {};
            const intellimizeCustomerId = $html.attr(
              'data-wf-intellimize-customer-id'
            );
            if (intellimizeCustomerId) {
              const optOutKey = `intellimize_opt_out_${intellimizeCustomerId}`;
              const intellimizeOptOut = localStorage.getItem(optOutKey);
              if (!intellimizeOptOut) {
                return;
              }
              localStorage.removeItem(
                `intellimize_opt_out_${intellimizeCustomerId}`
              );
              if (reload) {
                location.reload();
              }
            }
          },
          getIsOptedOut: function () {
            const intellimizeCustomerId = $html.attr(
              'data-wf-intellimize-customer-id'
            );
            return localStorage.getItem(
              `intellimize_opt_out_${intellimizeCustomerId}`
            );
          },
        };
      },
    };
  })
);
